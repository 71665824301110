import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'

import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpLogrEntity from '../entity';
import UtilsEntity from "../../../../utils/entity";

export default class ErpLogList extends DefaultController {
    tableElem: any;
    datatable: any;
    private detailModal: bootstrap.Modal;

    async init() {
        this.entity = "erp/logs"
        this.tableElem = jQuery('.datatables-users')
        this.detailModal = new bootstrap.Modal((document.querySelector("#erpLogModal") as HTMLElement));

        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return ErpLogrEntity.getEntityData(elem)
    }

    async getSubentities() {
        const r = await UtilsEntity.getAll("product_attributes");
        if (r.status === 200) {
            return r.data
        } else {
            return []
        }
    }

    bindListeners() {
        jQuery("body").delegate(".open_detail", "click", async (e) => {
            e.preventDefault();
            const order_number = e.currentTarget?.getAttribute("data-order-number")
            await Utils.showLoader();
            const r = await UtilsEntity.request(`/api/v1/erp/warehouse_location_product_mappings/by_order_number`, 'POST', {
                order_number: order_number,
            })
            let html = '<table class="table table-striped">';
            html += `<thead><tr><th>Product</th><th>Warehouse</th><th>Warehouse Location</th><th>Pallet</th><th>Customer</th><th>Serial</th>`
            const attributes = await this.getSubentities();
            attributes.forEach((attr: any) => {
                html += `<th>${attr.name}</th>`
            })
            html += `</tr></thead><tbody>`
            if (r && r.status === 200) {
                r.data.forEach((d: any) => {
                    html += `<tr>
                        <td>${d.product[0].name}</td>
                        <td>${d.warehouseLocation[0].warehouse[0].name}</td>
                        <td>${d.warehouseLocation[0].name}</td>
                        <td>${d.warehouseLocationPallet[0] ? d.warehouseLocationPallet[0].name : ""}</td>
                        <td>${d.customer[0] ? d.customer[0].company : ""}</td>
                        <td>${d.serials.join(",")}</td>`
                    attributes.forEach((attr: any) => {
                        html += `<td>${d.payload[attr.name] ? d.payload[attr.name] : ""}</td>`
                    })
                    html += `</tr>`
                })
            }
            html += '</tbody></table>'
            console.log("html", html)
            const modalTable = document.querySelector("#erpLogModalTable");
            const modalTitle = document.querySelector("#erpLogModalTitle");
            if (modalTable) {
                modalTable.innerHTML = html;
            }
            if (modalTitle) {
                modalTitle.innerHTML = order_number;
            }

            jQuery("#erpLogModalTable table").DataTable({
                dom:
                    '<"row me-2 align-items-center"' +
                    '<"col-md-2"<"me-3 m-3"l>>' +
                    '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                    '>t' +
                    '<"row mx-2 align-items-center justify-content-between"' +
                    '<"col-6"i>' +
                    '<"col-6 mt-3"p>' +
                    '>',
                language: {
                    sLengthMenu: '_MENU_',
                    search: '',
                    searchPlaceholder: `${Utils.translate('generic.search')}...`,
                    "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                    "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                    "paginate": {
                        "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                        "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                        "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                        "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                    },
                    "info": `${Utils.translate('generic.datatable.info.info')}`,
                    "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                    "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
                },
                buttons: []
            });
            this.detailModal.show();
            await Utils.hideLoader();
        })

    }

    createTable() {
        this.datatable = this.tableElem.DataTable({
            initComplete: () => {
                this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
            },
            ajax: `/api/v1/erp/actions/warehouse_location_product_map/by_order_number`,
            columns: [
                {data: 'order_number'},
                {data: 'stock'},
                {data: 'created_at'},
            ],
            order: [[2, 'desc']],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="#" data-order-number="${data}" class="open_detail">${data}</a>`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<span style="display:none">${full.created_at}</span>${new Date(Date.parse(`${full.created_at}Z`)).toLocaleString()}`;
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: []
        });
    }
}